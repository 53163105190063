<template>
  <div 
    class="c-crop"
  >
    <div class="c-crop__inner">
      <div class="c-crop__area">
        <vue-cropper
          v-if="dialog.file"
          ref="cropper"
          :src="dialog.file"
          :aspect-ratio="dialog.width / dialog.height"
          drag-mode="move"
          :guides="true"
          :background="true"
          alt="Source Image"
        />
        <button
          class="c-crop__zoom c-crop__zoom--in"
          @click="zoomIn()"
        >
          <img
            src="@/assets/images/icon-plus.svg"
            width="13"
          >
        </button>
        <button
          class="c-crop__zoom c-crop__zoom--out"
          @click="zoomOut()"
        >
          <img
            src="@/assets/images/icon-minus.svg"
            width="13"
          >
        </button>
      </div>
      <div class="c-crop__btn">
        <button
          class="c-crop__btn--save"
          @click="crop"
        >
          {{ $t("common.form.save") }}
        </button>
        <button
          class="c-crop__btn--cancel"
          @click="close"
        >
          Cancel
        </button>
      </div>
    </div>
  </div>
</template> 
<script>
import VueCropper from 'vue-cropperjs';
import 'cropperjs/dist/cropper.css';
export default {
  components: { VueCropper },
  props: {
    dialog: Object,
  },
  data() {
    return {
      imgSrc: null,
      croppedImageSrc: null, 
    }
  },
  mounted() {
    this.imgSrc = this.dialog.file; 
    // console.log(this.dialog);
  },
  methods: {
    zoomIn() {
      this.$refs.cropper.relativeZoom(0.1);
    },
    zoomOut() {
      this.$refs.cropper.relativeZoom(-0.1);
    },
    close() {
      this.$emit('closeCrop', false);
    },
    crop() {
      this.$refs.cropper
        .getCroppedCanvas({
          width: this.dialog.width,
          height: this.dialog.height,
        })
        .toBlob((blob) => {
          const metadata = {
            type: 'image/png',
          };
          const file = new File([blob], 'thumbnail.png', metadata);
          this.$emit('imgOut', file);
        });
    },
  },
};
</script>
