<template>
  <div>
    <b-overlay
      id="overlay-background"
      :show="isLoading"
      :variant="`transparent`"
      :opacity="0.5"
      :blur="`10px`"
      rounded="sm"
      spinner-type="grow"
    >
      <section>
        <validation-observer
          #default="{ handleSubmit }"
          ref="refFormObserver"
        >
          <b-form
            @submit.prevent="handleSubmit(onSubmit)"
            @reset.prevent="resetForm"
          >
            <b-row>
              <b-col>
                <b-card no-body>
                  <b-card-body>
                    <div class="row">
                      <div class="row">
                        <div class="col-12">
                          <b-row align-h="end">
                            <b-form-checkbox
                              v-model="inputData.statusCode"
                              value="ACTIVE"
                              unchecked-value="INACTIVE"
                              class="custom-control-primary mb-75"
                              name="check-button"
                              switch
                              inline
                            >{{ $t("common.form.publish") }}
                            </b-form-checkbox>
                          </b-row>
                        </div>
                        <div class="col-12">
                          <validation-provider
                            #default="validationContext"
                            name="title"
                            rules="required"
                          >
                            <b-form-group
                              :label="$t('common.general.title')"
                              label-for="title"
                            >
                              <b-form-input
                                id="title"
                                v-model="inputData.title"
                                :state="inputData.title ? true : false"
                                trim
                                :placeholder="$t('common.form.please_insert_data')"
                                @input="onInputTitle"
                              />

                              <b-form-invalid-feedback>
                                {{ validationContext.errors[0] }}
                              </b-form-invalid-feedback>
                            </b-form-group>
                          </validation-provider>
                        </div>
                        <div class="col-12">
                          <div class="row">
                            <div class="col-6">
                              <div class="row">
                                <div class="col-12">
                                  <validation-provider
                                    #default="validationContext"
                                    name="slug"
                                    rules="required"
                                  >
                                    <b-form-group
                                      :label="$t('common.general.slug')"
                                      label-for="slug"
                                    >
                                      <b-form-input
                                        id="slug"
                                        v-model="inputData.slug"
                                        :state="inputData.slug ? true : false"
                                        trim
                                        :placeholder="$t('common.form.please_insert_data')"
                                      />

                                      <b-form-invalid-feedback>
                                        {{ validationContext.errors[0] }}
                                      </b-form-invalid-feedback>
                                    </b-form-group>
                                  </validation-provider>
                                </div>
                              </div>
                            </div>
                            <div class="col-6">
                              <div class="row">
                                <div class="col-12">
                                  <validation-provider
                                    #default="validationContext"
                                    name="blogID"
                                    rules="required"
                                  >
                                    <b-form-group
                                      :label="$t('common.general.category')"
                                      label-for="blogID"
                                    >
                                      <b-form-select
                                        v-model="categorySelected"
                                        :options="listCategories"
                                        :state="categorySelected === null ? false : true"
                                      />
                                      <b-form-invalid-feedback>
                                        {{ validationContext.errors[0] }}
                                      </b-form-invalid-feedback>
                                    </b-form-group>
                                  </validation-provider>
                                </div>
                                <div class="col-12" />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-12">
                          <div class="row">
                            <div class="col-6">                              
                              <b-form-group
                                :label="$t('common.general.keywords')"
                                label-for="keywords"
                              >
                                <b-form-tags
                                  v-model="inputData.keywords"
                                  input-id="tags-basic"
                                  class="mb-2"
                                />
                              </b-form-group>
                            </div>
                            <div class="col-6">                         
                              <b-form-group
                                :label="$t('common.general.balise_title')"
                                label-for="balise_title"
                              >
                                <b-form-input
                                  id="balise_title"
                                  v-model="inputData.balise_title"
                                  trim
                                  :placeholder="$t('common.form.please_insert_data')"
                                />
                              </b-form-group>
                            </div>
                          </div>
                        </div>
                        <div class="col-12">
                          <b-form-group
                            :label="$t('common.general.balise_description')"
                            label-for="balise_description"
                          >
                            <b-form-textarea
                              id="balise_description"
                              v-model="inputData.baliseDescription"
                              :placeholder="$t('common.form.please_insert_data')"
                              rows="5"
                            />
                          </b-form-group>
                        </div>
                        <div class="col-12">
                          <div class="row">
                            <div class="col-6">                              
                              <b-form-group
                                :label="$t('common.general.image')"
                                label-for="image"
                              >
                                <b-media
                                  no-body
                                  class="mb-75"
                                >
                                  <b-media-aside>
                                    <b-overlay
                                      :show="isUpload"
                                      class="d-inline-block"
                                    >
                                      <b-img
                                        rounded
                                        :src="
                                          inputData.image ? inputData.image : imageDefault
                                        "
                                        height="85"
                                      />
                                    </b-overlay>
                                  </b-media-aside>

                                  <b-media-body class="mt-75 ml-75">
                                    <b-form-group class="mb-0">
                                      <b-button
                                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                        variant="primary"
                                        size="sm"
                                        class="mb-75 mr-75"
                                        @click="$refs.refInputEl.$el.click()"
                                      >
                                        {{ $t("common.general.upload") }}
                                      </b-button>
                                      <b-form-file
                                        ref="refInputEl"
                                        accept=".jpg, .png"
                                        :hidden="true"
                                        plain
                                        @input="handleUpload($event, 'IMAGE')"
                                      />
                                    </b-form-group>
                                    <b-card-text>Allowed JPG or PNG.</b-card-text>
                                  </b-media-body>
                                </b-media>
                              </b-form-group>
                            </div>
                            <div class="col-3">
                              <label
                                class="form-label"
                              >{{ $t('pages.article.alt') }}</label>
                              <textarea
                                v-model="inputData.imageAlt"
                                class="form-control"
                                rows="3"
                              />
                            </div>
                            <div class="col-3">
                              <label
                                class="form-label"
                              >{{ $t('pages.article.label_title') }}</label>
                              <textarea
                                v-model="inputData.imageTitle"
                                class="form-control"
                                rows="3"
                              />
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-6">                              
                              <b-form-group
                                :label="$t('common.general.cover')"
                                label-for="cover"
                              >
                                <b-media
                                  no-body
                                  class="mb-75"
                                >
                                  <b-media-aside>
                                    <b-overlay
                                      :show="isUploadCover"
                                      class="d-inline-block img-cover"
                                    >
                                      <b-img
                                        rounded
                                        :src="
                                          inputData.cover ? inputData.cover : imageDefault
                                        "
                                      />
                                    </b-overlay>
                                  </b-media-aside>

                                  <b-media-body class="mt-75 ml-75">
                                    <b-form-group class="mb-0">
                                      <b-button
                                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                        variant="primary"
                                        size="sm"
                                        class="mb-75 mr-75"
                                        @click="$refs.refInputElCover.$el.click()"
                                      >
                                        {{ $t("common.general.upload") }}
                                      </b-button>
                                      <b-form-file
                                        ref="refInputElCover"
                                        accept=".jpg, .png"
                                        :hidden="true"
                                        plain
                                        @input="handleUpload($event, 'COVER')"
                                      />
                                    </b-form-group>
                                    <b-card-text>Allowed JPG or PNG.</b-card-text>
                                  </b-media-body>
                                </b-media>
                              </b-form-group>
                            </div>
                            <div class="col-3">
                              <label
                                class="form-label"
                              >{{ $t('pages.article.alt') }}</label>
                              <textarea
                                v-model="inputData.coverAlt"
                                class="form-control"
                                rows="3"
                              />
                            </div>
                            <div class="col-3">
                              <label
                                class="form-label"
                              >{{ $t('pages.article.label_title') }}</label>
                              <textarea
                                v-model="inputData.coverTitle"
                                class="form-control"
                                rows="3"
                              />
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-6">                              
                              <b-form-group
                                :label="$t('pages.article.label_content_image')"
                                label-for="content_image"
                              >
                                <b-media
                                  no-body
                                  class="mb-75"
                                >
                                  <b-media-aside>
                                    <b-overlay
                                      :show="isUploadCover"
                                      class="d-inline-block img-cover --max-height"
                                    >
                                      <b-img
                                        v-if="contentImageUrl"
                                        rounded
                                        :src="contentImageUrl"
                                      />
                                    </b-overlay>
                                  </b-media-aside>
                                </b-media>
                              </b-form-group>
                            </div>
                            <div class="col-3">
                              <label
                                class="form-label"
                              >{{ $t('pages.article.alt') }}</label>
                              <textarea
                                v-model="inputData.contentBottomImageAlt"
                                class="form-control"
                                rows="3"
                              />
                            </div>
                            <div class="col-3">
                              <label
                                class="form-label"
                              >{{ $t('pages.article.label_title') }}</label>
                              <textarea
                                v-model="inputData.contentBottomImageTitle"
                                class="form-control"
                                rows="3"
                              />
                            </div>
                          </div>
                        </div>
                        <div class="col-12">
                          <div class="row">
                            <div class="col-6">
                              <div class="row">
                                <div class="col-6">
                                  <validation-provider
                                    name="order"
                                  >
                                    <b-form-group
                                      :label="$t('common.general.sort_index')"
                                      label-for="order"
                                    >
                                      <b-form-input
                                        id="order"
                                        v-model="inputData.order"
                                        type="number"
                                      />
                                    </b-form-group>
                                  </validation-provider>
                                </div>
                                <div class="col-6">
                                  <b-form-group
                                    :label="$t('common.general.bookmark')"
                                    label-for="bookmark"
                                  >
                                    <b-form-checkbox
                                      v-model="inputData.bookmark"
                                      value="true"
                                      unchecked-value="false"
                                      class="custom-control-primary"
                                      style="margin-top: 5px"
                                      name="check-button"
                                      switch
                                      inline
                                    />
                                  </b-form-group>
                                </div>
                              </div>
                            </div>
                            <div class="col-6" />
                          </div>
                        </div>
                        <div class="col-12">
                          <b-form-group
                            :label="$t('common.general.content')"
                            label-for="content"
                            class="ck-width"
                          >
                            <!-- <ckeditor
                              v-model="inputData.content"
                              :config="editorConfig"
                            /> -->
                            <textarea
                              name="editorForArticle"
                              style="width:920px;"
                            />
                          </b-form-group>
                        </div>
                        <div class="col-12 hr" />
                        <div
                          class="col-12"
                        >
                          <!-- Title -->
                          <b-row>
                            <b-col cols="12">
                              <div class="inline-wrapper">
                                <label class="subtitle">Bottom Popup</label>

                                <b-form-checkbox
                                  v-model="inputData.bottomPopupEnabled"
                                  value="true"
                                  unchecked-value="false"
                                  class="custom-control-primary"
                                  name="check-button"
                                  switch
                                  inline
                                />
                              </div>
                            </b-col>
                          </b-row>
                          <!-- Message -->
                          <b-row>
                            <b-col cols="12">
                              <validation-provider
                                #default="validationContext"
                                name="message"
                                rules="required"
                              >
                                <b-form-group
                                  :label="$t('common.popup.bottom_message_label')"
                                  label-for="message"
                                >
                                  <b-form-input
                                    id="message"
                                    v-model="bottomPopupContent.message"
                                    :state="getValidationState(validationContext)"
                                    trim
                                    :placeholder="$t('common.form.please_insert_data')"
                                  />

                                  <b-form-invalid-feedback>
                                    {{ validationContext.errors[0] }}
                                  </b-form-invalid-feedback>
                                </b-form-group>
                              </validation-provider>
                            </b-col>
                          </b-row>
                          <!-- Button Text -->
                          <b-row>
                            <b-col cols="12">
                              <validation-provider
                                #default="validationContext"
                                name="buttonText"
                                rules="required"
                              >
                                <b-form-group
                                  :label="$t('common.popup.bottom_button_text')"
                                  label-for="buttonText"
                                >
                                  <b-form-input
                                    id="buttonText"
                                    v-model="bottomPopupContent.buttonText"
                                    :state="getValidationState(validationContext)"
                                    trim
                                    :placeholder="$t('common.form.please_insert_data')"
                                  />

                                  <b-form-invalid-feedback>
                                    {{ validationContext.errors[0] }}
                                  </b-form-invalid-feedback>
                                </b-form-group>
                              </validation-provider>
                            </b-col>
                          </b-row>
                          <!-- Url -->
                          <b-row>
                            <b-col cols="12">
                              <validation-provider
                                #default="validationContext"
                                name="url"
                                rules="required"
                              >
                                <b-form-group
                                  :label="$t('common.general.url')"
                                  label-for="url"
                                >
                                  <b-form-input
                                    id="url"
                                    v-model="bottomPopupContent.url"
                                    :state="getValidationState(validationContext)"
                                    trim
                                    :placeholder="$t('common.form.please_insert_data')"
                                  />

                                  <b-form-invalid-feedback>
                                    {{ validationContext.errors[0] }}
                                  </b-form-invalid-feedback>
                                </b-form-group>
                              </validation-provider>
                            </b-col>
                          </b-row>
                        </div>
                        <div
                          class="col-12"
                        >
                          <!-- Title -->
                          <b-row>
                            <b-col cols="12">
                              <div class="inline-wrapper">
                                <label class="subtitle">Simulator</label>

                                <b-form-checkbox
                                  v-model="inputData.simulatorEnabled"
                                  value="true"
                                  unchecked-value="false"
                                  class="custom-control-primary"
                                  name="check-button"
                                  switch
                                  inline
                                />
                              </div>
                            </b-col>
                          </b-row>

                          <b-row>
                            <b-col cols="12">
                              <validation-provider
                                #default="validationContext"
                                name="titleSimulator"
                              >
                                <b-form-group
                                  :label="$t('common.simulator.title')"
                                  label-for="titleSimulator"
                                >
                                  <b-form-input
                                    id="titleSimulator"
                                    v-model="inputData.titleSimulator"
                                    :state="getValidationState(validationContext)"
                                    trim
                                    :placeholder="$t('common.form.please_insert_data')"
                                  />

                                  <b-form-invalid-feedback>
                                    {{ validationContext.errors[0] }}
                                  </b-form-invalid-feedback>
                                </b-form-group>
                              </validation-provider>
                            </b-col>
                          </b-row>

                          <b-row>
                            <b-col cols="12">
                              <validation-provider
                                #default="validationContext"
                                name="descriptionSimulator"
                              >
                                <b-form-group
                                  :label="$t('common.simulator.description')"
                                  label-for="descriptionSimulator"
                                >
                                  <b-form-input
                                    id="descriptionSimulator"
                                    v-model="inputData.descriptionSimulator"
                                    :state="getValidationState(validationContext)"
                                    trim
                                    :placeholder="$t('common.form.please_insert_data')"
                                  />

                                  <b-form-invalid-feedback>
                                    {{ validationContext.errors[0] }}
                                  </b-form-invalid-feedback>
                                </b-form-group>
                              </validation-provider>
                            </b-col>
                          </b-row>
                          <b-row>
                            <b-col cols="12">
                              <validation-provider
                                #default="validationContext"
                                name="btnSimulator"
                              >
                                <b-form-group
                                  :label="$t('common.simulator.button_label')"
                                  label-for="btnSimulator"
                                >
                                  <b-form-input
                                    id="btnSimulator"
                                    v-model="inputData.btnSimulator"
                                    :state="getValidationState(validationContext)"
                                    trim
                                    :placeholder="$t('common.form.please_insert_data')"
                                  />

                                  <b-form-invalid-feedback>
                                    {{ validationContext.errors[0] }}
                                  </b-form-invalid-feedback>
                                </b-form-group>
                              </validation-provider>
                            </b-col>
                          </b-row>

                          <b-row>
                            <b-col cols="12">
                              <validation-provider
                                #default="validationContext"
                                name="urlSimulator"
                              >
                                <b-form-group
                                  :label="$t('common.simulator.url')"
                                  label-for="urlSimulator"
                                >
                                  <b-form-input
                                    id="urlSimulator"
                                    v-model="inputData.urlSimulator"
                                    :state="getValidationState(validationContext)"
                                    trim
                                    :placeholder="$t('common.form.please_insert_data')"
                                  />

                                  <b-form-invalid-feedback>
                                    {{ validationContext.errors[0] }}
                                  </b-form-invalid-feedback>
                                </b-form-group>
                              </validation-provider>
                            </b-col>
                          </b-row>
                         
                        </div>

                      </div>

                      <div class="row">
                        <div class="col-12">
                          <b-button
                            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                            variant="primary"
                            block
                            type="submit"
                          >
                            {{ $t("common.form.save") }}
                          </b-button>
                        </div>
                      </div>
                    </div></b-card-body>
                </b-card>
              </b-col>
          
            </b-row>
          </b-form>
        </validation-observer>
        <CropImage
          v-if="crop.status"
          :dialog="crop"
          @imgOut="upload"
          @closeCrop="closePopup"
        />
      </section>
    </b-overlay>
    <ListRelatedPage :id="id" />
    <ListFAQPage :id="id" />
  </div>
</template>
<style lang="scss" scoped>
  .subtitle{
    font-weight: bold;
    font-size: 18px;
    line-height: 1.8;
    label{
      font-weight: bold;
      font-size: 18px;
      line-height: 1.8;
    }
  }
  div.hr{
    margin-top: 15px;
  }
  .ck-width{
    max-width: 920px;
  }
  .img-cover{
    max-width: 250px;
    min-width: 200px;
    img{
      width: 100%;
    }
    &.--max-height {
      max-width: 300px;
      max-height: 250px;
      overflow-y: hidden;
    }
  }
  .inline-wrapper{
    display: flex;
    justify-content: left;
    align-items: center;
    .custom-control-primary{
      margin-top: -5px;
      margin-left: 10px;
    }
  }
</style>
<script>
import {
  BFormFile,
  BRow,
  BCol,
  BCard,
  BCardText,
  BCardBody,
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  VBToggle,
  BFormTags,
  BFormInvalidFeedback,
  BMedia,
  BMediaBody,
  BMediaAside,
  BImg,
  BFormCheckbox,
  BOverlay,
  BFormSelect,
} from 'bootstrap-vue';
import { required } from '@validations';
import CropImage from '@/components/ImageCrop.vue';
import ListRelatedPage from '@/views/pages/article/ListRelatedPage.vue';
import ListFAQPage from '@/views/pages/article/ListFAQPage.vue';
import Ripple from 'vue-ripple-directive';
import formValidation from '@core/comp-functions/forms/form-validation';
import categoryServices from '@/services/categoryServices';
import articleServices from '@/services/articleServices';
import commonServices from '@/services/commonServices';
import cloudinaryService from '@/services/cloudinaryService';
import enums from '@/services/enumsServices';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { imageDefault, InputType, articleTemplates } from '@/services/constant';
// import $ from 'jquery';
import _ from 'lodash';
export default {
  components: {
    ListRelatedPage,
    ListFAQPage,
    CropImage,
    BFormFile,
    ValidationProvider,
    ValidationObserver,
    BRow,
    BCol,
    BCard,
    BCardText,
    BCardBody,
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BFormTags,
    BFormInvalidFeedback,
    BMedia,
    BMediaBody,
    BMediaAside,
    BImg,
    BFormCheckbox,
    BOverlay,
    BFormSelect,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },

  data() {
    return {
      InputType,
      imageDefault,
      isLoading: false,
      id: this.$route.query.id ? this.$route.query.id : null,
      inputData: {},
      required,
      categorySelected: null,
      listCategories: [],
      selectedUpload: '',
      isUpload: false,
      isUploadCover: false,
      templateSelected: null,
      articleTemplates,
      files: [],
      crop: {
        status: false,
        file: null,
      },
      bottomPopupContent: {
        message: '',
        buttonText: '',
        url: '',
      }, 
      ckInterval: null,
      contentImageCloudinary: [],
      imageCloudinary: {},
      coverCloudinary: {},
      originalInputData: null,
      contentImageUrl: null,
    };
  },
  computed: {
    uploadDisabled() {
      return this.files.length === 0;
    },
  },
  watch: {
    data(value) {
      this.data = value;
      this.inputData = JSON.parse(JSON.stringify(this.data));
      return this.data;
    },
  },
  async created() {
    await this.bottomPopupInitData();
    await this.initData();
  },
  async mounted(){    
    if (this.id) {
      await this.getDetails();
    } else {
      // eslint-disable-next-line no-undef
      await CKEDITOR.replace('editorForArticle', commonServices.getDefaultCkeditorConfig());
      this.inputData.simulatorEnabled = false;
      this.inputData.titleSimulator = 'Découvrez notre simulateur Impôt sur les sociétés';
      this.inputData.descriptionSimulator = 'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa';
      this.inputData.btnSimulator = 'Faire la simulation';
      this.inputData.urlSimulator = 'https://www.lesfrenchies.io/simulateurs';
    }
    this.ckInterval = commonServices.createCkEditorImageBrowse();
  },
  beforeDestroy(){
    // eslint-disable-next-line no-undef
    if (CKEDITOR.instances.editorForArticle) CKEDITOR.instances.editorForArticle.destroy();
  },
  setup() {
    const { refFormObserver, getValidationState, resetForm } = formValidation({});
    return {
      refFormObserver,
      getValidationState,
      resetForm,
    };
  },
  methods: {
    async onSubmit() {
      this.isLoading = true;
      if (!this.inputData.statusCode) {
        this.inputData.statusCode = 'INACTIVE';
      }
      this.inputData.blogID = this.categorySelected;
      this.inputData.bottomPopupMessage = this.bottomPopupContent.message;
      this.inputData.bottomPopupButton = this.bottomPopupContent.buttonText;
      this.inputData.bottomPopupUrl = this.bottomPopupContent.url;
 
      this.inputData.template = this.templateSelected || null;
      // eslint-disable-next-line no-undef
      this.inputData.content = CKEDITOR.instances.editorForArticle.getData();
      const imgList = commonServices.getListImagePropertyInText(this.inputData.content, 'src');
      this.contentCloudinary = await this.compareAndUploadCloudinary(imgList);
      this.inputData.contentCloudinary = this.contentCloudinary && this.contentCloudinary.length > 0 ? JSON.stringify(this.contentCloudinary) : null;
      this.inputData.imageCloudinary = this.imageCloudinary ? JSON.stringify(this.imageCloudinary) : null;
      this.inputData.coverCloudinary = this.coverCloudinary ? JSON.stringify(this.coverCloudinary) : null;
      this.inputData.order = this.inputData.order || null;
      this.inputData.duration = this.inputData.duration || null;
      await articleServices
        .createUpdate(this.inputData)
        .then((result) => {
          if (result) {
            if (result.errors) {
              commonServices.showError(this, 'msg.error.error');
            } else {
              commonServices.showSuccess(this, 'msg.success.success');
              setTimeout(() => {
                this.$router.push({ name: 'list-articles' });
              }, 1500) 
            }
          }
        })
        .catch(() => {
          commonServices.showError(this, 'msg.error.error');
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    async handleUpload(event, type) {
      this.selectedUpload = type;
      const file = event;
      const reader = new FileReader();
      reader.onload = (ev) => {
        this.crop.status = true;
        this.crop.file = ev.target.result;
        if (type !== 'COVER') {
          this.crop.width = 500;
          this.crop.height = 500;
        } else {
          this.crop.width = 1300;
          this.crop.height = 470;
        }
      };
      reader.readAsDataURL(file);
    },
    async upload(v) {
      let oldPublicCloudinary = '';
      const typeUpload = this.selectedUpload;
      if (typeUpload !== 'COVER') {
        this.isUpload = true;
        oldPublicCloudinary = cloudinaryService.getCloudinaryPublicIdByStr(this.inputData.imageCloudinary, this.inputData.image);
      } else {
        this.isUploadCover = true;
        oldPublicCloudinary = cloudinaryService.getCloudinaryPublicIdByStr(this.inputData.coverCloudinary, this.inputData.cover);
      }
  
      const reader = new FileReader();
      reader.readAsDataURL(v);
      reader.onload = async () => {
        this.crop.status = false;
        await commonServices
          .uploadImageAndCloudinary(v, 'article', enums.levelS3.public, oldPublicCloudinary || '')
          .then((result) => {
            if (typeUpload !== 'COVER') {
              this.inputData.image = result.imageUrl;
              this.imageCloudinary = { key: this.inputData.image, value: result.cloudinaryInfo ? JSON.parse(result.cloudinaryInfo) : null };
            } else {
              this.inputData.cover = result.imageUrl;
              this.coverCloudinary = { key: this.inputData.cover, value: result.cloudinaryInfo ? JSON.parse(result.cloudinaryInfo) : null };
            }
          })
          .finally(() => {
            if (typeUpload !== 'COVER') {
              this.isUpload = false;
            } else {
              this.isUploadCover = false;
            }
          });
      };
    },
    async getDetails() {
      this.isLoading = true;
      await articleServices
        .getDetail(this.id)
        .then((result) => {
          if (result) {
            this.inputData = result.data.getArticle;
            this.categorySelected = this.inputData.blogID;
            if (this.inputData.bottomPopupMessage) this.bottomPopupContent.message = this.inputData.bottomPopupMessage;
            if (this.inputData.bottomPopupButton) this.bottomPopupContent.buttonText = this.inputData.bottomPopupButton;
            if (this.inputData.bottomPopupUrl) this.bottomPopupContent.url = this.inputData.bottomPopupUrl;
 
            this.imageCloudinary = this.inputData.imageCloudinary ? JSON.parse(this.inputData.imageCloudinary) : null;
            this.coverCloudinary = this.inputData.coverCloudinary ? JSON.parse(this.inputData.coverCloudinary) : null;
            this.contentCloudinary = this.inputData.contentCloudinary ? JSON.parse(this.inputData.contentCloudinary) : null;
            this.templateSelected = this.inputData.template || null;
            const imageTags = cloudinaryService.getListTagInText(this.inputData.content);
            if (imageTags && imageTags.length > 0) {
              const lastedImg = imageTags[imageTags.length - 1];
              this.contentImageUrl = cloudinaryService.getPropValueInTag(lastedImg, 'src', 'img');
            }
            // eslint-disable-next-line no-undef
            CKEDITOR.replace('editorForArticle', commonServices.getDefaultCkeditorConfig(this.inputData.content));
            if (this.inputData.keywords) {
              this.inputData.keywords = JSON.parse(this.inputData.keywords);
            }
            this.originalInputData = _.cloneDeep(this.inputData);
          }
        })
        .catch(() => {})
        .finally(() => {
          this.isLoading = false;
        });
    },
    async initData() {
      this.isLoadingTable = true;
      this.listCategories = [];
      try {
        await categoryServices
          .getList()
          .then((result) => {
            if (result) {
              const list = result.data.listBlogs.items;
              if (list.length > 0) {
                list.forEach((element) => {
                  this.listCategories.push({
                    value: element.id,
                    text: element.title,
                  });
                });
              }
            }
          })
          .catch(() => {})
          .finally(() => {
          });
      } finally {
        this.isLoadingTable = false;
      }
    },
    closePopup(v) {
      this.crop.status = v;
    },
    onInputTitle() {
      this.inputData.slug = commonServices.slugify(this.inputData.title);
    },
    bottomPopupInitData() {
      this.bottomPopupContent.message = 'Commencer les démarches 1';
      this.bottomPopupContent.buttonText = 'Créer une SAS 1';
      this.bottomPopupContent.url = '#';
    }, 
    checkImageHadUploaded(urlImg){
      if (!this.inputData.contentCloudinary) return null;
      const checkData = JSON.parse(this.inputData.contentCloudinary);
      const exists = _.find(checkData, x => x.key === urlImg);
      if (exists) return exists;
      return null;
    },
    async compareAndUploadCloudinary(imgList){
      if (!imgList || imgList.length === 0) return null;
      const result = []
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < imgList.length; i++){
        const exists = this.checkImageHadUploaded(imgList[i])
        if (!exists){
          // upload
          
          // eslint-disable-next-line no-await-in-loop
          const cloudinaryQuery = await cloudinaryService.uploadImageCloudinary(imgList[i]);
          const cloudinaryData = cloudinaryQuery.data.uploadImageCloudinary;
          const cloudinaryObj = cloudinaryData ? JSON.parse(cloudinaryData) : null;
          if (cloudinaryObj) result.push({ key: imgList[i], value: cloudinaryObj })
        } else {
          result.push(exists);
        }
      }
      return result.length > 0 ? result : null;
    },
  },
};
</script>
