<template>
  <b-overlay
    id="overlay-background"
    :show="isLoading"
    :variant="`transparent`"
    :opacity="0.5"
    :blur="`10px`"
    rounded="sm"
    spinner-type="grow"
  >
    <!-- Header -->
    <div
      class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
    >
      <h5 class="mb-0">
        {{ inputData.id ? $t("common.form.edit") : $t("common.form.add") }}
      </h5>

      <feather-icon
        class="ml-1 cursor-pointer"
        icon="XIcon"
        size="16"
        @click="onClose()"
      />
    </div>
    <validation-observer
      #default="{ handleSubmit }"
      ref="refFormObserver"
    >
      <b-form
        @submit.prevent="handleSubmit(onSubmit)"
        @reset.prevent="resetForm"
      >
        <b-card no-body>
          <b-card-body>
            <div class="row">
              <div class="col-12">
                <validation-provider
                  #default="validationContext"
                  name="title"
                  rules="required"
                >
                  <b-form-group
                    :label="$t('common.general.title')"
                    label-for="title"
                  >
                    <b-form-input
                      id="title"
                      v-model="inputData.title"
                      :state="getValidationState(validationContext)"
                      trim
                      :placeholder="$t('common.form.please_insert_data')"
                    />

                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </div>
              <div
                class="col-12"
                style="height: 250px;"
              >
                <b-form-group
                  :label="$t('common.general.description')"
                  label-for="description"
                >
                  <quill-editor
                    ref="myQuillEditor"
                    v-model="inputData.description"
                    style="height: 180px; white-space: pre; margin-top: -20px;"
                    :options="editorOption"
                  />
                </b-form-group>
              </div>
              <div class="col-12">
                <b-form-checkbox
                  v-model="inputData.statusCode"
                  value="ACTIVE"
                  unchecked-value="INACTIVE"
                  class="custom-control-primary mb-75"
                  name="check-button"
                  switch
                  inline
                >{{ $t("common.form.publish") }}
                </b-form-checkbox>
              </div>
              <div class="col-12">
                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="primary"
                  block
                  type="submit"
                >
                  {{ $t("common.form.save") }}
                </b-button>
              </div>
            </div>
          </b-card-body>
        </b-card>
      </b-form>
    </validation-observer>
  </b-overlay>
</template>

<script>
// eslint-disable-next-line import/no-extraneous-dependencies
import 'quill/dist/quill.core.css';
// eslint-disable-next-line import/no-extraneous-dependencies
import 'quill/dist/quill.snow.css';
// eslint-disable-next-line import/no-extraneous-dependencies
import 'quill/dist/quill.bubble.css';

import { quillEditor } from 'vue-quill-editor';
import {
  BButton,
  BForm,
  BFormInput,
  BFormGroup,
  // BFormTextarea,
  VBToggle,
  BFormInvalidFeedback,
  BOverlay,
  BCard,
  BCardBody,
  BFormCheckbox,
} from 'bootstrap-vue';
import { required } from '@validations';
import Ripple from 'vue-ripple-directive';
import formValidation from '@core/comp-functions/forms/form-validation';
import articleFAQServices from '@/services/articleFAQServices';
import commonServices from '@/services/commonServices';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
export default {
  components: {
    BCardBody,
    BCard,
    ValidationProvider,
    ValidationObserver,
    BButton,
    BForm,
    BFormInput,
    BFormGroup,
    // BFormTextarea,
    BFormInvalidFeedback,
    BOverlay,
    BFormCheckbox,
    quillEditor,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  props: {
    // eslint-disable-next-line vue/require-default-prop
    id: {
      type: String,
      required: false,
    },
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isLoading: false,
      inputData: { statusCode: 'INACTIVE' },
      required,
      toggle: false,
      editorOption: {
        modules: {
          toolbar: ['bold', 'italic', 'underline'],
          // keyboard: {
          //   bindings: {
          //     tab: {
          //       key: 9,
          //       // eslint-disable-next-line no-unused-vars
          //       handler(range, context) {
          //         return true;
          //       },
          //     },
          //   },
          // },
        },
        formats: [
          'italic', 'bold', 'underline',
        ],
      },
    };
  },
  watch: {
    data(value) {
      // console.log(value);
      this.data = value;
      this.inputData = JSON.parse(JSON.stringify(this.data));
      return this.data;
    },
  },
  created() {},
  setup() {
    const { refFormObserver, getValidationState, resetForm } = formValidation(
      {},
    );
    return {
      refFormObserver,
      getValidationState,
      resetForm,
    };
  },
  methods: {
    async onSubmit() {
      if (!this.id) {
        commonServices.showError(this, 'msg.article.article_not_exist');
        return;
      }
      this.isLoading = true;
      this.inputData.articleID = this.id;
      if (!this.inputData.statusCode) this.inputData.statusCode = 'INACTIVE';
      await articleFAQServices
        .createUpdate(this.inputData)
        .then((result) => {
          if (result) {
            if (result.errors) {
              commonServices.showError(this, 'msg.error.error');
            } else {
              commonServices.showSuccess(this, 'msg.success.success');
              this.$emit('refetchData');
            }
          }
        })
        .catch(() => {
          commonServices.showError(this, 'msg.error.error');
        })
        .finally(() => {
          this.isLoading = false;
          this.onClose();
        });
    },
    async getDetails() {
      if (!this.id) return;
      this.isLoading = true;
      await articleFAQServices
        .getDetail(this.id)
        .then((result) => {
          if (result) {
            this.inputData = result.data.getArticleFAQ;
          }
        })
        .catch(() => {})
        .finally(() => {
          this.isLoading = false;
        });
    },
    onClose() {
      this.$emit('close');
    },
  },
};
</script>
