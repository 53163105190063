/* eslint-disable no-unused-vars */
<template>
  <b-sidebar
    id="add-new-related" 
    v-model="toggle"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @change="onChangeSidebar"
  >
    <template>
      <b-overlay
        id="overlay-background"
        :show="isLoading"
        :variant="`transparent`"
        :opacity="0.5"
        :blur="`10px`"
        rounded="sm"
        spinner-type="grow"
      >
        <!-- Header -->
        <div
          class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
        >
          <h5 class="mb-0">
            {{ $t("common.form.add") }}
          </h5>

          <feather-icon
            v-b-toggle.add-new-related
            class="ml-1 cursor-pointer"
            icon="XIcon"
            size="16"
          />
        </div>
        <validation-observer
          #default="{ handleSubmit }"
          ref="refFormObserver"
        >
          <b-form
            @submit.prevent="handleSubmit(onSubmit)"
            @reset.prevent="resetForm"
          >
            <b-card
              no-body
            >
              <b-card-body>
                <div class="row">
                  <div class="col-12">
                    <validation-provider
                      #default="validationContext"
                      name="title"
                      rules="required"
                    >
                      <b-form-group
                        :label="$t('common.general.title')"
                        label-for="title"
                      >
                        <v-select
                          v-model="articleSelected"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :options="dataListArticles"
                          label="title" 
                          :reduce="item => item.id"
                          :state="articleSelected === null ? false : true"
                        >
                          <template #option="{ title }">
                            <span> {{ title }}</span>
                          </template>
                        </v-select>

                        <b-form-invalid-feedback>
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </div> 
                  <div class="col-12">
                    <b-button
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      variant="primary"
                      block
                      type="submit"
                    >
                      {{ $t("common.form.save") }}
                    </b-button>
                  </div>
                </div>
              </b-card-body>
            </b-card>
          </b-form>
        </validation-observer>
      </b-overlay>
    </template></b-sidebar>
</template>

<script>
import vSelect from 'vue-select';
import {
  BSidebar,
  BButton,
  BForm,
  BFormGroup, 
  VBToggle,
  BFormInvalidFeedback,
  BOverlay,
  BCard,
  BCardBody,
} from 'bootstrap-vue';
import { required } from '@validations';
import Ripple from 'vue-ripple-directive';
import formValidation from '@core/comp-functions/forms/form-validation';
import articleServices from '@/services/articleServices';
import articleRelatedServices from '@/services/articleRelatedServices';
import commonServices from '@/services/commonServices';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
export default {
  components: {
    BCardBody,
    BCard,
    BSidebar,
    ValidationProvider,
    ValidationObserver,
    BButton,
    BForm, 
    BFormGroup, 
    BFormInvalidFeedback,
    BOverlay,
    vSelect,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  props: {
    id: {
      type: String,
      required: false,
    }, 
    data: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      isLoading: false,
      inputData: {},
      required,
      toggle: false,
      dataListArticles: [],
      articleSelected: null,
    };
  }, 
  created() {
    this.getList();
  }, 
  setup() {
    const { refFormObserver, getValidationState, resetForm } = formValidation(
      {},
    );
    return {
      refFormObserver,
      getValidationState,
      resetForm,
    };
  },
  methods: {
    onChangeSidebar(){
      this.inputData = {};
      this.articleSelected = null;
    },
    async getList() {
      this.dataListArticles = [];
      if (!this.id) return;
      this.isLoadingTable = true;
      let items = [];
      // eslint-disable-next-line prefer-const
      let variables = {
        filter: {
          id: {
            ne: this.id,
          },
        },
      }
      let graphqlData = await articleServices.getListExcludeById(variables);
      let result = graphqlData.data.listArticles;
      items = items.concat(result.items);
      while (result.nextToken) {
        // eslint-disable-next-line dot-notation
        variables['nextToken'] = result.nextToken
        // eslint-disable-next-line no-await-in-loop
        graphqlData = await articleServices.getListExcludeById(
          variables,
        );
        result = graphqlData.data.listArticles;
        items = items.concat(result.items);
      }
      this.dataListArticles = items;
      this.isLoadingTable = false;
    }, 
    async onSubmit() {
      if (!this.id){
        commonServices.showError(this, 'msg.article.article_not_exist');
        return;
      }
      this.isLoading = true;
      this.inputData.articleID = this.articleSelected;
      this.inputData.relatedID = this.id;
      await articleRelatedServices
        .createUpdate(this.inputData)
        .then((result) => {
          if (result) {
            if (result.errors) {
              commonServices.showError(this, 'msg.error.error');
            } else {
              commonServices.showSuccess(this, 'msg.success.success'); 
              this.$emit('refetchData');
            }
          }
        })
        .catch(() => {
          commonServices.showError(this, 'msg.error.error');
        })
        .finally(() => {
          this.isLoading = false; 
          this.toggle = false;
        });
    },  
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
